import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import PageTitle from '../components/PageTitle'
import Seo from '../components/seo'
import {sightAndSoundPageHolder, sasHolder} from '../styles/PageStyles.module.scss'
import {motion} from 'framer-motion'

const Wrap = ({ condition, wrapper, children }) => 
condition ? wrapper(children) : children;


const SAS = ({sas, image})=> {

  const variants = {
    pre: {
      y: "4rem",
      opacity:0
    },
    vis: {
      y: "0rem",
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    },
    post: {
      y: "4rem",
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    }
  }

  return (
    <motion.li variants={variants} className={sasHolder}>
      <Wrap
        condition={sas.links}
        wrapper={children => <a href={sas.links[0].url} title={sas.title} target="_blank" rel="noreferrer" >{children}</a>}
        >
        <GatsbyImage image={getImage(image)} alt={sas.title} />
      </Wrap>
      <div className="sasInfo">
        <h3>{sas.title}</h3>
        { sas.description && <p>{sas.description}</p> }
        <ul className="links">
          {
            sas.links.map(link=> (
            <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer" title={sas.title}>{link.text}</a></li>
            ))
          }
        </ul>
      </div>
    </motion.li>
  )
}




export default function SightsAndSoundsPage({data}) {

  const allSAS = data.allDataJson.nodes[0].sightsAndSounds
  const allImg = data.allFile.nodes


  return (
    <article className={sightAndSoundPageHolder}>
      <Seo title="Sight and Sound" />
      <PageTitle>Sight & Sound</PageTitle>
      <section>
          <h5>A selection of music videos by Dot and her collaborators.</h5>
      </section>
      <section>
        {

          allSAS?.length > 0 ? <ul>
            {
            allSAS.map(sas => {
              const image = allImg.find(img=> img.name===sas.image)
              return <SAS sas={sas} image={image} key={sas.title} /> 
            })
            }
          </ul> :
          <p>No Sights and Sounds found</p>
        }
      </section> 

    </article>
  )
}

export const query = graphql`
query SASQuery {
  allDataJson {
    nodes {
      sightsAndSounds {
        description
        image
        title
        links {
          text
          url
        }
      }
    }
  }
  allFile(filter: {relativeDirectory: {eq: "sas"}}) {
    nodes {
      relativeDirectory
      name
      childImageSharp {
        gatsbyImageData(
          width: 550
          height:260
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`